.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.leaderboard {
    padding: 20px;
    margin: 1rem;
}

.participantImage {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-bottom: 8px;
    border: #5147c7 2px solid;
}

.tiebreakerImage {
    display: flex;
    width: 35;
    height: 35px;
    /* margin-bottom: 8px; */
    justify-content: center;
}

.placementName {
    /* If you need specific styles for the text, add here */
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #b6b6b6;
    background-color: #464646;
    padding: 5px;
    border-radius: 5px;
    margin-top: 1rem;
}

/* If screen width is less than 360px, change font size */
@media screen and (max-width: 400px) {
    .participantName {
        color: #00000000;
    }

    .tiebreakerImage {
        width: 25px;
        height: 25px;
    }
}

.participant {
    background-color: var(--main-accent-color) !important;
    border: #464646 1px solid;
}

.participantSmall {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 400px) {
    .participantNameSmall {
        display: none;
    }
}

.score {
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #b6b6b6;
    margin-bottom: 6px;
    padding-left: 5px;
}

.placementScore {
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -40%); */
    z-index: 1;
    /* Other style properties */
    font-size: 1rem;
    font-weight: bold;
    font-style: italic;
}


.placements {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* Center the items vertically */
    margin-bottom: 20px;
    margin-top: 20px;
    /* Spacing from the leaderboard list */
    background-color: #292929b7;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(235, 71, 71, 0.15);
    margin: 1rem;
    padding: 0.5rem;
    color: #ffffff;
}

.placementItem {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    animation: hoverEffect 6s ease-in-out infinite;

}


.gold {
    width: 30%;
    height: 30%;
}

.silver {
    width: 30%;
    height: 30%;
}

.bronze {
    width: 30%;
    height: 30%;
}

.placementRank {
    justify-content: center;
    /* transform: translate(-50%, 110%); */
    /* z-index: 1; */
    font-weight: bold;
    font-size: 1.5rem;
    color: #b6b6b6;
    /* Add other properties like margin-top if needed to adjust the specific position */
}

.placementRank span {
    justify-content: center;
}

@keyframes hoverEffect {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }
}