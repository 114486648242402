.toggleMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #292929;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(235, 71, 71, 0.15);
  margin: 1rem 1rem 0 1rem;
  padding: 0.5rem;
  color: #ffffff;
}