.Results {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}


.backButton {
    display: block;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 1rem;
    font-size: 1rem;
    background-color: var(--button-color) !important;
    color: white !important;
}

.backContainer {
    display: flex;
    flex-basis: 10%;
    margin: 1rem;
}