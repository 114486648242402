:root {
    --main-bg-color: rgba(37, 37, 37, 0.8);
    --main-dark-color: #00031F;
    --main-light-color: #383838;
    --main-accent-color: #464646;
    --main-secondary-color: #272727;
    --main-text-color: #fff;
    --main-hover-color: #e2dfdf;
    --button-color: rgba(37, 37, 37, 0.8);
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    mix-blend-mode: screen;
}

.table {
    display: flex;
    flex-direction: column;
    background: var(--main-bg-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    margin: auto;
    max-width: 600px;
    /* Skrink the table to fit the screen */

}

.returnButton {
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-size: 1rem;
    /* A complementary color to the blue */
    color: white;
}



.participant {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;

}

.participantImage {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-bottom: 8px;
    border: var(--main-accent-color) 2px solid;
}

@media (max-width: 750px) {
    .participantImage {
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width: 600px) {
    .participantName {
        display: none;
    }
}

.participantName {
    /* If you need specific styles for the text, add here */
    margin-left: 8px;
    margin-bottom: 15px;
    color: var(--main-text-color);
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
}

.outcomesTable {
    width: 90%;
    border-collapse: collapse;
    /* Matching the category title box from the guess form */
    margin-top: 20px;
    border-radius: 8px;
    margin-left: 5%;
}

.outcomesTable tr:nth-child(even) {
    background-color: var(--main-accent-color);
    opacity: 0.9;
    /* Light grey */
}

.outcomesTable tr:nth-child(odd) {
    background-color: var(--main-secondary-color);
    opacity: 0.9;
    /* Dark grey */
}

.outcomesTable th {
    /* Dark blue */
    color: var(--main-text-color);
    /* White */
    font-weight: bold;
    /* Make the text bold */
    border-left: 1px solid var(--main-light-color);
    /* Darken the left side of the table */
}

.outcomesTable td {
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 2%;
}

.outcomesTable td:first-child {
    border-left: none;
    /* Remove the left border from the first cell */
}

.outcomesTable td:hover {
    background-color: var(--main-hover-color);
    color: #403E10;
    font-weight: bold;

    transition-delay: 0s;
    transition-duration: 0.4s;
    transition-property: all;
    transition-timing-function: line;
}

@media (max-width: 800px) {

    .container td:nth-child(1),
    .container th:nth-child(1) {
        display: none;
    }
}


.category {
    padding: 8px;
    text-align: left;
    /* Centers the text in the cells */
    color: #fff;
    font-size: 0.75rem;
}

.category:hover {
    background-color: rgba(41, 65, 250, 0.9);
    /* Darkens the background on hover */
}

.categoryCalled {
    font-weight: lighter;
    color: #808080
}

.outcomesTable td.correctGuess,
.outcomesTable tr.goldCategory td.correctGuess {

    background-color: rgb(245, 210, 10);
    color: #402310 !important;
    font-weight: bold;

    box-shadow: #7F7C21 -1px 1px, #7F7C21 -2px 2px, #7F7C21 -3px 3px, #7F7C21 -4px 4px, #7F7C21 -5px 5px, #7F7C21 -6px 6px;
    transform: scale(1.02);

    transition-delay: 0s;
    transition-duration: 0.4s;
    transition-property: all;
    transition-timing-function: line;
}

.tableHeader {
    font-weight: bold;
    /* Makes the category headers bold */
    /* Additional styles if needed */
}

.goldCategory {
    /* background-color: #4c00ff; */
    font-weight: bold;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    transform: scaleX(1.01);
    /* Shadow gives depth */
    margin: -1px;
    /* Optional: help the cell to stand out */
    position: relative;
    /* Make sure it's above other content */
    text-align: center;
}

.tiebreaker {
    background-color: #3b2db8 !important;
    opacity: 0.8;
}

.tiebreaker td.correctGuess {
    background-color: #9670ee !important;
    color: #141414 !important;
    font-weight: bold;
    box-shadow: #7F7C21 -1px 1px, #7F7C21 -2px 2px, #7F7C21 -3px 3px, #7F7C21 -4px 4px, #7F7C21 -5px 5px, #7F7C21 -6px 6px;
    transform: scale(1.02);
    transition-delay: 0s;
    transition-duration: 0.4s;
    transition-property: all;
    transition-timing-function: line;
}

@media (max-width: 750px) {
    .table {
        width: 100%;
        /* Ensures the table takes the full width */
    }

    .tableHeader {
        font-size: 1rem;
        /* Ensures the text doesn't overflow */
    }
}

.tooltip-content {
    z-index: 1000;
    /* Ensure the z-index is applied */
    position: absolute;
    text-align: left;
}