.formControl {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;

}

.categoryLabel {
    color: #fff;
    margin-bottom: 0.5rem;
}

.gameLabel {
    color: #fff;

}

.MuiSelect-root {
    color: #fff;
}

.select {
    margin: 0 10px 0 10px;
    background-color: #292929;
    transition: all 0.3s ease-in-out;
    min-width: 200px;
}

.submitButton {
    display: flex;
    width: 50%;
    margin: 1rem auto !important;
    padding: 12px 20px;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    mix-blend-mode: screen;
}