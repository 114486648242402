/* ProtectedRoute.module.css */
.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(rgba(255, 255, 255, 0), #e85709), url('../images/TGA24_KV_01.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.main {
  /* Render everything at the bottom of the screen */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
}