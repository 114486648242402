.buttonMenu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 50px;
    flex-wrap: wrap;
}

.buttonMenu button {
    margin: 0 8px 8px 0;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background: #252525;
    color: #d6d6d6;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.logoutButton {
    display: flex;
    justify-content: center;
    color: white !important;
    background-color: #d41919 !important;
}

.buttonMenu button:hover {
    background: #525252;
    color: rgb(0, 0, 0);
}

.logoutButton button:hover {
    background: #f05151;
    color: rgb(211, 11, 11);
}