.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    z-index: 1001;
}

.winnersList {
    list-style: none;
    padding: 0;
    margin: 0;
    color: white;
    font-size: 24px;
    text-align: center;
    animation: scroll 60s linear infinite;
}

.winnerItem {
    margin-bottom: 100px;
}

.placementImage {
    width: 200px;
    height: 200px;
    margin-right: 10px;
}

.tiebreakerImages {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    justify-content: center;
}

.tiebreakerImage {
    width: 40px;
    height: 40px;
    margin-right: 5px;
}

.achievement {
    margin-bottom: 100px;
}

.achievement h3 {
    color: #FFD700;
    font-weight: bold;
    margin-top: 2px;
    /* Reduced from 5px to 2px */
    margin-block-end: 2px;
    /* Reduced from 5px to 2px */
}

.achievement p {
    margin: 2px;
    /* Reduced from 5px to 2px */
    margin-block-end: 2px;
    /* Reduced from 5px to 2px */

}

.achievementDetails {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-size: 16px;
    /* Added padding to reduce spacing */
}

.achievementName {
    font-size: 26px;
    margin: 2px;
    /* Added margin to reduce spacing */
}


@keyframes scroll {
    0% {
        transform: translateY(70%);
    }

    100% {
        transform: translateY(-70%);
    }
}