.title {
    margin: 0;
}

.countdownWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 -8px 0 -8px;
}

.countdownItem {
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #ffffff !important;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
}

.countdownText {
    margin: 0;
    font-size: 48px;
    font-weight: 200;
    display: flex;
    justify-content: center;
    color:#038EFD !important;
    font-family: Impact;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
}

.logo {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.time {
    margin: 0;
    font-size: 60px ;
    font-weight: 800 ;
}

.small {
    font-size: 18px;
}

/* Add this to your Countdown.module.css */
@media (max-width: 500px) {
    .countdownWrapper {
      width: 90%;
      justify-content: center;
        margin: 0 auto;
    }

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%; /* Ensures the logo container takes the full width */
      margin: 10px auto; /* Centers the logo vertically and horizontally with some margin */
    }
    
    .logo img {
      max-width: 90%; /* Ensure the logo image is never wider than its container */
      height: auto; /* Keep the aspect ratio of the image */
    }
  
    .time {
        font-size: clamp(16px, 8vw, 60px); /* Values are minimum size, preferred size, maximum size */
      }
      
      .time-text {
        font-size: clamp(10px, 2.5vw, 18px); /* Adjust minimum and maximum sizes accordingly */
      }
  
    /* Optionally, if the countdownItem class has too much padding or flex-basis, you can adjust these for smaller screens */
    .countdownItem {
      padding: 4px; /* Reduce padding as needed */
      flex-basis: auto; /* Allow item to shrink or expand as needed */
    }
  }