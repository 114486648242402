/* Table.module.css */
.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.reloadButton {
  display: block;
  padding: 10px;
  width: 90%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 5% 0px 5% !important;
  font-size: 1rem;
  background-color: var(--button-color) !important;
  color: white !important;
}

.tableBox {
  flex-grow: 1;
  overflow: auto;
  margin: 8px;
  /* equivalent to m={1} in sx prop */
}

.menuBox {
  margin: 8px;
  /* equivalent to m={1} in sx prop */
}

.paper {
  padding: 8px;
  /* You may want to adjust this padding */
}

@media screen and (max-width: 600px) {
  .tableContainer {
    flex-direction: column;
  }

}