.logo {
    position: absolute;
    top: 5px;
    left: 5px;
    height: 35px;
    pointer-events: auto;
    z-index: 1000;
}

@media screen and (max-width: 500px) {
    .logo {
        display: none;
    }

}

.tab {
    color: white;
}

.Mui-selected {
    background-color: var(--main-accent-color);
    color: white;
}

.Mui-indicator {
    background-color: white;
}