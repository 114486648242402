:root {
    --main-bg-color: rgba(37, 37, 37, 0.8);
    --main-dark-color: #00031F;
    --main-light-color: #061371;
    --main-accent-color: #0176FA;
    --main-secondary-color: #038EFD;
    --main-text-color: #fff;
    --main-hover-color: #e2dfdf;
    --button-color: #0176FA;
}

.TitleBox {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--main-bg-color);
    border: 1px solid #646459;
    color: var(--main-text-color);
    border-radius: 1rem;
    margin: 1rem;
}

.Title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.BackButton {
    background-color: var(--main-bg-color);
    border: 1px solid #646459;
    color: var(--main-text-color);
    padding: 10px;
    border-radius: 1rem;
    margin: 1rem;
    text-align: center;
    cursor: pointer;
}

.Rules {
    background-color: var(--main-bg-color);
    border: 1px solid #646459;
    color: var(--main-text-color);
    padding: 10px;
    border-radius: 1rem;
    margin: 1rem;

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 1rem;
    }

    h3 {
        color: #e85709;
    }
}