.tooltipMetacritic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align items to the start */
    padding-left: 10px;
}

.tooltipMetacritic p,
.tooltipMetacritic a {
    margin: 0;
    /* Remove default margin */
    padding: 10px 10px 10px 5px;
    /* Adjust padding as needed */
}

.tooltipContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.scoreBox {
    padding: 5px 15px 5px 15px !important;
    border-radius: 10px;
    color: rgb(37, 37, 37);
    display: inline-block;
    font-size: 2rem;
    font-weight: bold;
}

.scoreBox.green {
    background-color: #00CE7A;
}

.scoreBox.yellow {
    background-color: #FFBD3F;
    color: black;
    /* Ensure text is readable on yellow background */
}

.scoreBox.red {
    background-color: #FF6874;
}

.metacriticLink a {
    color: rgb(228, 129, 0);
    font-size: 1.3rem;
    text-decoration: none;
    margin-top: 1em;
    display: inline-block;
    font-weight: bold;
    padding: .5em;
    margin-left: -.5em;
    margin-top: 0;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

    &:before,
    &:after {
        position: absolute;
        content: '';
        border-bottom: 3px solid rgb(228, 137, 0);
        border-radius: 1em;
        bottom: .3em;
        transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &:before {
        width: 1em;
        transform-origin: left;
    }

    &:after {
        width: 82%;
        left: 1em;
        transform: translateX(110%);
    }

    &:hover:before {
        transform: scaleX(0.3);
    }

    &:hover:after {
        transform: translateX(0);
    }

}