:root {
  --main-bg-color: rgba(37, 37, 37, 0.8);
  --main-dark-color: #00031F;
  --main-light-color: #061371;
  --main-accent-color: #0176FA;
  --main-secondary-color: #038EFD;
  --main-text-color: #fff;
  --main-hover-color: #e2dfdf;
  --button-color: #0176FA;
}

.layoutContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonColumn,
.loadingContainer,
.formColumn,
.buttonBar {
  display: flex;
  justify-content: center;
}

.buttonColumn {
  flex-basis: 10%;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}

.formColumn {
  flex-basis: 80%;
}

.guessForm {
  background: rgba(26, 26, 26, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
  margin: auto;
}

.loadingContainer {
  align-items: center;
  height: 100vh;
  mix-blend-mode: screen;
}

.submitButton,
.backButton {
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 1rem;
  font-size: 1rem;
  background-color: var(--button-color) !important;
  color: white !important;
}

.submitButton:hover,
.backButton:hover {
  background-color: var(--main-secondary-color);
}

.categoryTitleBox,
.gameOfTheYearHeader,
.tiebreakerHeader {
  border-radius: 1rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  padding: 0 1rem;
}

.categoryTitleBox {
  background-color: var(--main-bg-color);
  border: 1px solid #646459;
  color: var(--main-text-color);
}

.tiebreakerHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #8078f1;
  border: 2px solid #242155;
  color: var(--main-text-color);
  margin: 1rem 0 1rem 0;
  padding: 0px 5px;
  transform: scaleX(1.05);

}

.gameOfTheYearHeader {
  background-color: rgb(249, 200, 41);
  border: 2px solid rgb(255, 230, 3);
  color: #8b4307;
}

.radioButtonContainer {
  display: flex;
  margin: 10px 0;
  color: #b6b6b6;
  align-items: center;
  justify-content: left;
}

.hiddenRadio {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.customRadio {
  appearance: none;
  border: 2px solid #8b4307;
  background-color: transparent;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  margin-left: 15px;
  margin-right: 10px;
}

.game-info {
  display: none;
}

.radioButtonContainer:hover .game-info {
  display: inline-block;
}

.customRadio::before,
.radioButtonContainer input:checked+.customRadio::before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  background-color: #000000;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease;
}

.radioButtonContainer input:checked+.customRadio::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: #c56a20;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: appear 0.8s;
}

@keyframes appear {
  0% {
    transform: translate(-50%, -50%) scale(0);
    background-color: #8b4307
  }

  45% {
    transform: translate(-50%, -50%) scale(1.6);
    background-color: #c56a20;
  }

  50% {
    transform: translate(-50%, -50%) scale(1.7);
    background-color: var(#c56a20);
  }

  55% {
    transform: translate(-50%, -50%) scale(1.6);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    background-color: #c56a20;
  }
}

.buttonBar {
  justify-content: space-between;
  width: 90%;
  margin-left: 5%;
  margin-top: 1rem;
}

@media (max-width: 500px) {
  .layoutContainer {
    align-items: center;
  }
}