/* Background.css */
.backgroundContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1; /* To make sure your content goes over the background */
}